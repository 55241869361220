<template>
    <div class="myRenewalOrder">
      <div class="report_con_item">
        <h6 style="margin-bottom: 30px">订单状态</h6>
        <el-steps align-center v-if="detailData.renewalOrderStatus != 60" :space="170" :active="detailData.renewalOrderStatus/10" finish-status="success">
          <el-step title="支付成功"></el-step>
          <el-step title="确认信息中"></el-step>
          <el-step title="提交官费中"></el-step>
          <el-step title="等待官方收据"></el-step>
          <el-step title="订单完成"></el-step>
        </el-steps>

        <el-steps align-center v-else :space="170" :active="3" finish-status="success">
          <el-step title="支付成功"></el-step>
          <el-step title="确认信息中"></el-step>
          <el-step title="退款成功"></el-step>
        </el-steps>
      </div>
      <div class="report_con_item">
        <h6>订单信息</h6>
        <div class="report_list_item">
          <span>订单编号:</span>
          <p>{{detailData.renewalOrderNumber}}</p>
        </div>
        <div class="report_list_item">
          <span>创建时间:</span>
          <p>{{detailData.orderCreateTime}}</p>
        </div>
        <div class="report_list_item">
          <span>订单金额:</span>
          <p>{{detailData.orderSummaryAmount+"元"}}</p>
        </div>
      </div>
      <div class="report_con_item">
        <h6>商标信息</h6>
        <div class="maturity">
          <ul class="maturity_title">
            <li>
              <span>商标名称</span>
              <span>商标图样</span>
              <span>申请号</span>
              <span>类别</span>
              <span>提交方式</span>
              <span>续展官费</span>
              <span>宽展费</span>
              <span>代理费</span>
              <span>续展官费总计</span>
            </li>
          </ul>
          <ul >
            <li :key="index" v-for="(item,index) in detailTableData">
              <el-tooltip class="item" effect="dark" :content="item.trademarkName" placement="top">
                <span>{{item.trademarkName}}</span>
              </el-tooltip>
              <span><img style="width: 48px" :src="queryDataUrl(item.trademarkImageUrl)" alt=""></span>
              <span>{{item.trademarkApplyNo}}</span>
              <span>{{item.renewalNiceCode}}</span>
              <span>{{item.submitType == 0 ? '电子' : '纸质'}}</span>
              <span>{{item.renewalAmount}}</span>
              <span>{{item.extendedAmount}}</span>
              <span>{{item.agencyAmount}}</span>
              <span>{{item.renewalSummaryAmount}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="report_con_item">
        <h6>续展信息</h6>
        <div class="content">
          <div class="content_1">
            <div class="report_list_item active">
              <span>申请人名称(中文):</span>
              <p>{{businessCustomer.applicantName}}</p>
            </div>
            <div class="report_list_item active">
              <span>申请人地址:</span>
              <p>{{businessCustomer.applicantAddress}}</p>
            </div>
            <div class="report_list_item active">
              <span>联系人名称:</span>
              <p>{{businessCustomer.contactPersonName}}</p>
            </div>
            <div class="report_list_item active">
              <span>{{idTypeEent(businessCustomer.idType)}}:</span>
              <p>{{businessCustomer.idCode}}</p>
            </div>
          </div>
          <div>
            <div class="report_list_item active">
              <span>联系人邮编:</span>
              <p>{{businessCustomer.contactPostCode}}</p>
            </div>
            <div class="report_list_item active">
              <span>联系人电话:</span>
              <p>{{businessCustomer.contactPersonPhone}}</p>
            </div>
          </div>
        </div>
        <ul class="uploadInfo">
          <li v-for="(item, index) in fileList">
            <img :src="item.fileUrl" alt="">
            <span>{{item.fileType == 10 ? '营业执照' : item.fileType == 11 ? '身份证' : '委托书'}}</span>
          </li>
        </ul>
      </div>
      <div class="report_con_item">
        <h6>支付信息</h6>
        <div class="report_list_item">
          <span>支付时间:</span>
          <p>{{detailData.orderPaymentTime == null ? '--' : detailData.orderPaymentTime}}</p>
        </div>
        <div class="report_list_item">
          <span>支付方式:</span>
          <span>{{detailData.orderPaymentType == 0 ? '未知' : detailData.orderPaymentType == 1 ? '支付宝' : detailData.orderPaymentType == 2 ? '微信' : '对公支付'}}</span>
        </div>
        <div class="report_list_item">
          <span>支付金额:</span>
          <p>
            {{(detailData.isPaid == 'n' && detailData.orderSummaryAmount == 0) || detailData.orderSummaryAmount == null  ? '--' : detailData.orderSummaryAmount+"元"}}
          </p>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
      name: "myRenewalOrder",
      data(){
        return {
          fileList: [],
          businessCustomer: {},
          detailTableData: [],
          detailData: {},
          id: null
        }
      },
      mounted() {
        this.id = this.$route.query.id
        this.detailAjax(this.id)
        this.getInfo(this.id)
      },
      methods:{
        queryDataUrl(data) {
          if (data && data.includes("data:image")) return data;
          else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
          else return "";
        },
        getInfo(renewalOrderNumber) {
          this.$axios.post("/custody/business/file/record/list", {
            orderNumber: renewalOrderNumber,
            orderCategory: 'trademark'
          }).then(({data}) => {
            if(data.code == 0){
              this.fileList = data.data
            }
          })
        },
        queryDataUrl(data) {
          if (data && data.includes("data:image")) return data;
          else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
          else return "";
        },
        idTypeEent(val) {
          let name = ''
          switch (val) {
            case '1':
              name = "统一信用代码";
              break;
            case '2':
              name = "身份证";
              break;
            case '3':
              name = "护照";
              break;
            case '4':
              name = "其它";
              break;
          }
          return name
        },
        // 详情
        async detailAjax (renewalOrderNumber) {
          let data = await this.$axios.post(`/custody/renewal/order/detail?renewalOrderNumber=` + renewalOrderNumber,{
            renewalOrderNumber: renewalOrderNumber
          });
          if (data.data.code == 0) {
            this.detailData = data.data.data.renewalOrderList[0];
            this.detailTableData = data.data.data.renewalOrderList;
            this.businessCustomer = data.data.data.businessCustomer
          }
        },
      }
    }
</script>

<style lang="less" scoped>
 .myRenewalOrder {
   height: 767px;
   overflow-y: auto;
   padding: 20px 40px;
   background: #FFFFFF;
   box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
   border-radius: 15px 15px 15px 15px;
 .report_con_item {
   /deep/.el-step__head.is-success {
     color: #386FFC;
     border-color:#386FFC!important;
     .el-step__icon.is-text {
       background: #386FFC;
     }
     .el-step__icon-inner.is-status.el-icon-check {
       color: white;
     }
   }
   /deep/.el-step__main {
     .el-step__title.is-success {
       color: #2D3748!important;
     }
   }
   margin-bottom: 12px;
   .uploadInfo {
     margin-left: 24px;
     display: flex;
     justify-content: flex-start;
     li {
       display: flex;
       align-items: center;
       flex-direction: column;
       margin-right: 63px;
       img {
         width: 88px;
         height: auto;
       }
       span {
         font-weight: 500;
         font-size: 12px;
         color: #798593;
       }
     }
   }
   .maturity {
     padding: 30px 21px 20px;
     box-sizing: border-box;
     background: #E4EDFF;
     border-radius: 5px 5px 5px 5px;
     .maturity_title {
       width: calc(100% - 10px);
       margin-bottom: 11px;
       li {
         display: flex;
         padding: 0px 10px;
         box-sizing: border-box;
         span {
           text-align: left;
           display: block;
           width: 10%;
           font-weight: bold;
           font-size: 14px;
           color: #2D3748;
           &:nth-of-type(1) {
             width: 20%;
           }
           &:nth-last-of-type(1) {
             width: 10%;
           }
         }
       }
     }
     ul:nth-of-type(2) {
       width: 100%;
       max-height: 160px;
       margin-bottom: 10px;
       background: #E4EDFF;
       overflow-y: auto;
       //result-ico
       // 滚动条的宽度
       &::-webkit-scrollbar {
         width: 10px; // 横向滚动条
         height: 10px; // 纵向滚动条 必写
       }

       // 滚动条的滑块
       &::-webkit-scrollbar-thumb {
         background: hsla(0, 0%, 53%, .1);
         border-radius: 10px
       }

       &::-webkit-scrollbar-track {
         background: hsla(0, 0%, 53%, .1)
       }
       li {
         padding: 24px 11px;
         box-sizing: border-box;
         height: 40px;
         background: #FFFFFF;
         box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.08);
         border-radius: 8px;
         display: flex;
         align-items: center;
         margin-bottom: 11px;
         span {
           text-align: left;
           display: block;
           align-items: center;
           white-space: nowrap;        /* 禁止换行 */
           overflow: hidden;           /* 隐藏溢出内容 */
           text-overflow: ellipsis;    /* 显示省略号 */
           font-weight: 500;
           font-size: 14px;
           color: #434E5F;
           width: 10%;
           &:nth-of-type(1) {
             padding-right: 5px;
             box-sizing: border-box;
             width: 20%;
           }
           &:nth-last-of-type(1) {
             width: 10%;
           }
           &.status {
             i.you {
               width: 53px;
               height: 20px;
               display: flex;
               align-items: center;
               justify-content: center;
               font-weight: 400;
               font-size: 12px;
               color: #4F5AED;
               background: #EAECFF;
               border-radius: 30px;
             }
             i.err {
               width: 53px;
               height: 20px;
               display: flex;
               align-items: center;
               justify-content: center;
               font-weight: 400;
               font-size: 12px;
               color: #D12953;
               background: #FAF0F3;
               border-radius: 30px;
             }
           }
         }
       }
     }

   }
   .table {
     background: #E4EDFF!important;
     border-radius: 8px;
     padding: 0px 20px 10px;
     overflow: hidden !important;
     /deep/.vxe_grid_header th{
       font-weight: 600;
       font-size: 14px;
       color: #464F60!important;
       height: 70px;
       background: #E4EDFF!important;
     }
     /deep/.el-table__row {
       height: 64px!important;
       background: #FFFFFF!important;
       box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.08)!important;
       border-radius: 8px!important;
     }
     /deep/.has-gutter {
       background: #E4EDFF!important;
     }
     /deep/.el-table__header-wrapper {
       background: #E4EDFF!important;
     }
     /deep/.el-table__body-wrapper {
       background: #E4EDFF!important;
     }
   }
 h6 {
   color: #424242;
   font-size: 16px;
   position: relative;
   margin-bottom: 12px;
 &::before {
    content: "";
    width: 4px;
    height: 18px;
    background-color: @blue;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    left: -11px;
  }
 }
   .content{
     display: flex;
     padding: 0 17px 27px;
     box-sizing: border-box;
     .content_1 {
       margin-right: 50px;
     }
   }
 .report_list_item {
  &.active {
    span {
      display: inline-block;
      width: 130px;
      font-size: 14px;
      color: #2D3748;
    }
  }
   line-height: 28px;
   display: flex;
   span {
     display: inline-block;
     width: 74px;
     font-size: 14px;
     color: #2D3748;
   }
   p {
     color: #2D3748;
     display: inline-block;
     /*width: 600px;*/
     vertical-align: top;
   }
 }
 }
 }
</style>
